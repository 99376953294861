import React from "react";
import LogoInstagram from "../../assets/images/logo_instagram.png";
import { ReactComponent as LogoBlog } from "../../assets/images/logo_blog.svg";
import { ReactComponent as LogoYoutube } from "../../assets/images/logo_youtube.svg";
import { ReactComponent as LogoKakao } from "../../assets/images/logo_kakao.svg";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="w-full h-[375px] sm:h-full bg-[#585858]">
      <div className="pt-24 pb-20 sm:py-20 text-white flex sm:flex-col sm:pl-5 sm:text-xs justify-center space-x-56 sm:space-x-0">
        <div className="flex flex-col">
          <p>주식회사 컨텍터스</p>
          <p className="mt-auto mb-6 sm:mt-5">
            대표 : 송희국 | 서울특별시 강남구 테헤란로86길 14 2,4층 (윤천빌딩)
          </p>
          <p>사업자등록번호 801-88-01899</p>
          <p>통신판매업 2022-서울강남-00405</p>
          <div className="flex space-x-5 sm:space-x-2 mt-auto sm:mt-5 z-10">
            <span className="font-bold cursor-pointer" onClick={() => navigate("terms/privacy")}>
              개인정보처리방침
            </span>
            <span className="font-light cursor-pointer" onClick={() => navigate("terms/service")}>
              서비스이용약관
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:mt-10">
          <p className="font-bold">문의 & 상담</p>
          <p>M. contactus@doongji.co.kr</p>
          <p>T. 1577-9489</p>
          <div className="flex space-x-10">
            <div className="flex items-center space-x-5 my-8 sm:space-x-6 sm:ml-1 z-10">
              <a
                className="z-10 hover:scale-105 transition-all"
                aria-label="link to instagram"
                href="https://www.instagram.com/doongji_official/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LogoInstagram} alt="instagram" />
              </a>
              <a
                className="z-10 hover:scale-105 transition-all"
                aria-label="link to naver blog"
                href="https://blog.naver.com/contactus_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoBlog className="cursor-pointer" />
              </a>
              <a
                className="z-10 hover:scale-105 transition-all"
                aria-label="link to youtube"
                href="https://www.youtube.com/channel/UCtwQFJIG7abadZ-QEXtVGgA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoYoutube className="cursor-pointer" />
              </a>
              <a
                className="z-10 hover:scale-105 transition-all"
                aria-label="link to youtube"
                href="https://pf.kakao.com/_WxnHRs/chat?from=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoKakao className="cursor-pointer" />
              </a>
            </div>
          </div>
          <p className="sm:text-[10px]">&copy;2022. (주)컨텍터스 All Right Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
